import axios from "axios";

const baseURL = "https://betexch-api.nakshtech.info/";

const unsellectedBetAPI = axios.create({
  baseURL,
});

export const fetchUnsellectedBet = async (data, token) => {
  try {
    const response = await unsellectedBetAPI.post('getUnSetteledBet', data, {
      headers: {
        Authorization: token,
      },
    });
    console.log("unsellectedBetAPI", response );
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export default unsellectedBetAPI;

// authAPI.js
import axios from "axios";

const baseURL = "https://betexch-api.nakshtech.info/";
//const baseURL = "http://localhost:9476/";

const authAPI = axios.create({
  baseURL,
});

export const login = (userData) => authAPI.post("/login", userData);

export default authAPI;

import React, { useState, useEffect } from "react";
import SliderH from "./SliderH";
import { FaPlay } from "react-icons/fa";
import "./MatchesDetail.css";
import cricket from "../../Media/5.png";
import { BsFillPersonFill } from "react-icons/bs";
import tennis from "../../Media/4.png";
import football from "../../Media/6.png";
import MatchData from "./MatchData";
import InPlayMatchData from "./InPlayMatchData";
import colorimg from "../../Media/output-onlinepngtools (8).png";
import aircraft from "../../Media/output-onlinepngtools (5).png";
import { NavLink } from "react-router-dom";
// import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { fetchMatchName } from "../../Redux/Slices/matchNameSlice";
// import { fetchData } from "../../Redux/Slices/apiSlices";
const MatchesDetail = () => {
  const [activeIndex, setActive] = useState(4);
  const [isVisible, setIsVisible] = useState(true);

  const dispatch = useDispatch();

  const userId = sessionStorage.getItem("userData");
  const token = sessionStorage.getItem("token");

  const { matchName } = useSelector((state) => state.matchInfo) || [];

  useEffect(() => {
    dispatch(fetchMatchName({ id: activeIndex, token })).unwrap();
  }, [dispatch, activeIndex]);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsVisible((prevVisible) => !prevVisible);
    }, 500);
    return () => clearInterval(interval);
  }, []);
  return (
    <div className=" col-lg-8 p-2 center-div">
      <SliderH />
      <div className="MainArea ">
        <div className="sport-div bg-dark row">
          <div className="sports-horiz-row d-flex">
            <div
              onClick={() => {
                setActive(4);
              }}
              className={`single-sport-div ${activeIndex === 4 && "is-active"}`}
            >
              <div className="row">
                <div className="col-10 d-flex flex-column justify-content-end align-items-center ">
                  <div
                    className={`round-sport-div d-flex justify-content-center align-items-center ms-1 mt-2 ${
                      activeIndex === 4 && "is-activ"
                    }`}
                  >
                    <img
                      className=" sport-img ms-1"
                      src={cricket}
                      alt="cricket"
                    />
                  </div>
                  <p
                    className={`ms-1 sport-name mt-1 ${
                      activeIndex === 4 && "is-act"
                    }`}
                  >
                    CRICKET
                  </p>
                </div>
                {/* <div className="col-2 ">
                  <div className="num-div d-flex justify-content-center ">
                    13
                  </div>
                </div> */}
              </div>
            </div>
            <div
              onClick={() => {
                setActive(1);
              }}
              className={`single-sport-div ${activeIndex === 1 && "is-active"}`}
            >
              <div className="row">
                <div className="col-10 d-flex flex-column justify-content-end align-items-center ">
                  <div
                    className={`round-sport-div d-flex justify-content-center align-items-center ms-1 mt-2 ${
                      activeIndex === 1 && "is-activ"
                    }`}
                  >
                    <img className=" sport-img " src={football} alt="cricket" />
                  </div>
                  <p
                    className={`ms-1 sport-name mt-1 ${
                      activeIndex === 1 && "is-act"
                    }`}
                  >
                    SOCCER
                  </p>
                </div>
                {/* <div className="col-2">
                  <div className="num-div d-flex justify-content-center">
                    13
                  </div>
                </div> */}
              </div>
            </div>
            <div
              onClick={() => {
                setActive(2);
              }}
              className={`single-sport-div ${activeIndex === 2 && "is-active"}`}
            >
              <div className="row">
                <div className="col-10 d-flex flex-column justify-content-center align-items-center ">
                  <div
                    className={`round-sport-div d-flex justify-content-center align-items-center ms-1 mt-2 ${
                      activeIndex === 2 && "is-activ"
                    }`}
                  >
                    <img
                      className=" sport-img ms-1"
                      src={tennis}
                      alt="cricket"
                    />
                  </div>
                  <p
                    className={`ms-1 sport-name mt-1 ${
                      activeIndex === 2 && "is-act"
                    }`}
                  >
                    TENNIS
                  </p>
                </div>
                {/* <div className="col-2">
                  <div className="num-div d-flex justify-content-center align-items-center">
                    13
                  </div>
                </div> */}
              </div>
            </div>

            <div className="single-sport-div ms-4">
              <NavLink to="/ColorGame" className="nav-link">
                <div className="blink-img-div">
                  {isVisible && (
                    <img
                      className="image-size img-fluid "
                      src={colorimg}
                      alt="a"
                    />
                  )}
                </div>
                <p className="ms-1  sport-name">COLOR</p>
              </NavLink>
            </div>
            <div className="single-sport-div ms-1">
              <NavLink to="/Aviator" className="nav-link">
                <div className="image-div">
                  {isVisible && (
                    <img
                      className=" a-image-size img-fluid mt-2"
                      src={aircraft}
                      alt="a"
                    />
                  )}
                </div>
                <p className="ms-1 mt-1 sport-name mt-3">AVIATOR</p>
              </NavLink>
            </div>
          </div>

          {/* <----------------------MatchData-----------------------> */}
          {matchName?.map((item, index) => {
            const date = new Date(item.openDate);

            date.setHours(date.getHours() - 5);
            date.setMinutes(date.getMinutes() - 30);
            return new Date(date) >= new Date() ? (
              <MatchData key={index} data={item} activeIndex={activeIndex} />
            ) : (
              <InPlayMatchData
                key={index}
                data={item}
                activeIndex={activeIndex}
              />
            );
          })}

          {/* <InPlayMatchData /> */}
          {/* <MatchData />
          <MatchData />
          <MatchData /> */}
        </div>
      </div>
    </div>
  );
};

export default MatchesDetail;

import React, { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";

function SessionTable({
  tabData,
  handleModal,
  setbetType,
  setmarketType,
  setbetDetails,
  setDataValue,
  setcountryName,
  setrealtimeData,
}) {
  const { sessionTeams } = useSelector((state) => state.sessionStack);
  const { eventTypeid, id } = useSelector((state) => state.auth.currentMatch);

  const [prevB1, setPrevB1] = useState(tabData?.b1);
  const [prevL1, setPrevL1] = useState(tabData?.l1);
  const [b1Blink, setB1Blink] = useState(false);
  const [l1Blink, setL1Blink] = useState(false);

  useEffect(() => {
    if (tabData?.b1 !== prevB1) {
      setB1Blink(true);
      setPrevB1(tabData?.b1);
      setTimeout(() => setB1Blink(false), 1000);
    }

    if (tabData?.l1 !== prevL1) {
      setL1Blink(true);
      setPrevL1(tabData?.l1);
      setTimeout(() => setL1Blink(false), 1000);
    }
  }, [tabData?.b1, tabData?.l1]);

  const handleClick = (
    betDetails,
    bettype,
    marketType,
    countryName,
    betvalue
  ) => {
    handleModal(true);
    setbetType(bettype);
    setmarketType(marketType);
    setbetDetails(betDetails);
    setcountryName(countryName);
    setDataValue(betvalue);
    setrealtimeData(true);
  };
  
  // console.log("sessionStack",sessionTeams)

  return (
    <div className="betting-team-info_1">
      <div className="row">
        <div className="col-7 betting-t-n py-2">
          <p className="ms-2 mb-0 d-flex ">{tabData.rname}</p>
          {sessionTeams.map((ele, index) => (
            <p
              key={index}
              className={`ms-5 
               ${ele.amount >= 0 ? "success-1" : "zero"}`}
            >
              {" "}
              {tabData.rname == ele.team && ele.amount}
            </p>
          ))}
        </div>
        <div className="col-5 d-flex pos_re">
          {(tabData.status === "SUSPENDED" ||
            tabData.status === "Ball Running") && (
            <div className="tr_data_1">
              <button className="spnd">{tabData.status}</button>
            </div>
          )}

          {tabData.l1 !== 0.0 ? (
            <div
              className={` orange-bet-btn mb-0 ${l1Blink ? "blink-green" : ""}`}
              onClick={() =>
                handleClick(
                  {
                    type: "Session Market",
                    team: tabData.rname,
                    back: "0",
                    lay: tabData.l1,
                    BackSize1: "",
                    LaySize1: tabData.ls1,
                    min: "",
                    max: "",
                    matchid: id,
                    ballsess: "0",
                    sid: tabData.sid,
                    srno: tabData.srno,
                    WinPerc: "0",
                    LayPrice2: tabData.l1,
                    LaySize2: tabData.ls1,
                    BackPrice2: tabData.b1,
                    BackSize2: tabData.bs1,
                    GameStatus: tabData.status,
                    gtype: "Session Market",
                    rem: "",
                    eventID: eventTypeid,
                  },
                  "lay",
                  "Session Market",
                  tabData.rname,
                  {
                    valueOf: tabData.l1,
                    team: tabData.rname,
                    sid: tabData.sid,
                    status: tabData.status,
                  }
                )
              }
            >
              <div className="d-flex flex-column align-items-center">
                {Math.round(tabData.l1)}
                <span> {Math.round(tabData.ls1)}</span>
              </div>
            </div>
          ) : (
            <div className=" orange-bet-btn mb-0">
              <div className="d-flex flex-column align-items-center">
                0.0
                <span> 0.0</span>
              </div>
            </div>
          )}

          {tabData.b1 != 0.0 ? (
            <div
              className={`blue-bet-btn mb-0 ms-1  ${
                b1Blink ? "blink-green" : ""
              }`}
              onClick={() =>
                handleClick(
                  {
                    type: "Session Market",
                    team: tabData.rname,
                    back: tabData.b1,
                    BackSize1: tabData.bs1,
                    lay: "0",
                    LaySize1: "",
                    min: "",
                    max: "",
                    lay: "0",
                    matchid: id,
                    ballsess: "0",
                    sid: tabData.sid,
                    srno: "0",
                    WinPerc: "0",
                    LayPrice2: tabData.l1,
                    LaySize2: tabData.ls1,
                    BackPrice2: tabData.b1,
                    BackSize2: tabData.bs1,
                    GameStatus: tabData.status,
                    gtype: "Session Market",
                    rem: "",
                    eventID: eventTypeid,
                  },
                  "back",
                  "Session Market",
                  tabData.RunnerName,
                  {
                    valueOf: tabData.b1,
                    team: tabData.rname,
                    sid: tabData.sid,
                    status: tabData.status,
                  }
                )
              }
            >
              <div className="d-flex flex-column align-items-center">
                {Math.round(tabData.b1)}
                <span> {Math.round(tabData.bs1)}</span>
              </div>
            </div>
          ) : (
            <div className=" blue-bet-btn ms-1 mb-0">
              <div className="d-flex flex-column align-items-center">
                0.0
                <span> 0.0</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default memo(SessionTable);

// authSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { login } from "../api/authAPI";

const initialState = {
  isAuth: false,
  user: null,
  error: null,
  isLoading: false,
  isDevToolsOpen: false,
  currentMatch: {},
};

// Define an asynchronous thunk for login
export const loginUser = createAsyncThunk(
  "auth/loginUser",
  async ({ username, password, ipaddress }, { rejectWithValue, getState }) => {
    const { auth } = getState();
    if (auth.isDevToolsOpen) {
      return rejectWithValue("DevTools are open. API calls are blocked.");
    }
    try {
      const response = await login({ username, password, ipaddress });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logoutUser: () => {
      return initialState;
    },
    updateCurrentMatch(state, action) {
      // console.log("authSlice", state, action);
      state.currentMatch = action.payload;
    },
    Authenticate(state) {
      // console.log("authSlice", state, action);
      state.isAuth = true;
    },
    setDevToolsOpen: (state, action) => {
      state.isDevToolsOpen = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        // console.log("uidlogin", action);
        state.isLoading = false;

        state.user = action.payload;
        state.error = null;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export const { logoutUser, updateCurrentMatch, Authenticate, setDevToolsOpen } =
  authSlice.actions;

export default authSlice.reducer;

import React, { useEffect } from "react";
import { useState } from "react";
import RightSidebar from "../RightSidebar/RIghtSidebar";
import "./Aviator.css";
import { CiCirclePlus } from "react-icons/ci";
import { FaMinus } from "react-icons/fa";
import { FaPlus } from "react-icons/fa";
import AviatorMain from "./AviatorMain";
import { io } from "socket.io-client";
import Progressbar from "./Progressbar";
import FlewAway from "./FlewAway";
import AviatorButton from "./AviatorButton";
import { API } from "../../API/API";
import { useDispatch } from "react-redux";
import { json, useNavigate } from "react-router-dom";
import { logoutUser } from "../../Redux/Slices/authSlice";
import { toast } from "react-toastify";
import { Loader } from "./Loader";

const Aviator = () => {
  const [loader, setLoader] = useState(true);
  const token = sessionStorage.getItem("token");
  const userId = sessionStorage.getItem("userData");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [aviatorData, setAviatorData] = useState({
    CheckPeriodNo: "",
    betData: "",
    betTime: 0,
    period: 0,
    status: 0,
    timer: "",
  });
  const [status, setStatus] = useState(3);

  const [betHistory, setBetHistory] = useState([]);
  const [resultHistory, setresultHistory] = useState([]);
  const [historyBoolean, sethistoryBoolean] = useState(false);
  const SOCKET_URL = "https://betx-aviator-new.nakshtech.info/";

  useEffect(() => {
    const AviatorSocket = io(SOCKET_URL);
    // AviatorSocket.emit("aviator", userId);

    AviatorSocket.on("aviator", (data) => {
      const parse1 = JSON.parse(data);
      // console.log("data", parse1);
      setStatus(parse1.status);
      setAviatorData(parse1);
      if (parse1.status === 0) {
        setLoader(false);
      }
    });

    return () => {
      AviatorSocket.disconnect();
    };
  }, []);

  const AvaitorbetHistory_API = async () => {
    try {
      let res = await API.post(
        `/AviatorBetHistory`,
        { uid: userId, Gameid: "2", Limit: "10" },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      const data = res.data.data[0];
      // console.log("betHistory->", data);
      if (data.length > 0) {
        setBetHistory(data);
      }
    } catch (e) {
      console.log("Error While Fetch AvbetCancle_API", e);
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        sessionStorage.clear();
        dispatch(logoutUser());
        navigate("/");
      }
    }
  };

  const AvaitorResultHistory_API = async () => {
    try {
      let res = await API.post(
        "AviatorResult",
        {
          Gameid: 2,
          Limits: 15,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      // console.log("resultHistory", res);
      const data = res.data.data[0];
      // console.log("betHistory->", data);
      if (data.length > 0) {
        setresultHistory(data);
      }
    } catch (e) {
      console.log("Error While Fetch AvbetCancle_API", e);
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        sessionStorage.clear();
        dispatch(logoutUser());
        navigate("/");
      }
    }
  };

  useEffect(() => {
    AvaitorbetHistory_API();
    AvaitorResultHistory_API();
  }, [historyBoolean]);

  useEffect(() => {
    if (betHistory.length > 0 && betHistory[0].Betstatus === "Pending") {
      AvaitorbetHistory_API();
    }

    AvaitorResultHistory_API();
  }, [aviatorData.period]);

  return (
    <>
      <div className="col-lg-10 ">
        <div className="row home ">
          <div className="col-lg-8 p-2">
            <div className="Aviator-main-div pt-1 p-2">
              <div className="aviator-h-div d-flex px-2 pt-1">
                <h3>Aviator</h3>
                <p className="mt-2 px-1">({aviatorData.period})</p>
              </div>
              <div className="small-btns-div d-flex">
                {resultHistory.map((ele, index) => (
                  <div className="small-g-btn mx-1" key={index}>
                    {ele.Multiplayer}x
                  </div>
                ))}
              </div>
              {loader === true ? (
                <div className="canvas-main-div mt-3 mx-auto">
                  {/* <Loader /> */}
                  <Progressbar betTime={1} />
                </div>
              ) : (
                <div className="canvas-main-div mt-3 mx-auto">
                  {status === 0 && (
                    <Progressbar betTime={aviatorData.betTime} />
                  )}
                  <div>
                    {status === 1 && (
                      <div>
                        <AviatorMain aviatorData={aviatorData} />
                        <h1 className="result-bet">
                          {(aviatorData.timer == "0.1" ||
                          aviatorData.timer == "1000.00")
                            ? "Blast"
                            : aviatorData.timer}
                          {(aviatorData.timer == "0.1" ||
                            (aviatorData.timer == "1000.00") ?" ": "x")}
                        </h1>
                      </div>
                    )}
                  </div>
                  {status === 2 && <FlewAway result={aviatorData.timer} />}
                </div>
              )}
            </div>
            <AviatorButton
              sethistoryBoolean={sethistoryBoolean}
              historyBoolean={historyBoolean}
              socketData={aviatorData}
              loader={loader}
            />

            <div className="My-Bets-table">
              <div className="color-game-h mt-2">
                <div className="px-2 pt-1">
                  <p>Open Bets</p>
                </div>
              </div>
              <div className="table-h-div row table-responsive">
                <table>
                  <thead>
                    <tr>
                      <th>S.No.</th>
                      <th>Period</th>
                      <th>Multiplier</th>
                      <th>Bet Amount</th>
                      <th>Cashout Amount</th>
                      <th>Date</th>
                      <th>Remark</th>
                    </tr>
                  </thead>
                  <tbody className="text-center">
                    {betHistory.length > 0 ? (
                      betHistory.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item.peroid}</td>
                          <td>{item.Multiplayer}</td>
                          <td>{item.Amount}</td>
                          <td>{item.Winamount}</td>
                          <td>{item.edate}</td>
                          <td
                            className={
                              item.Betstatus === "Loss" ||
                              item.Betstatus === "Pending"
                                ? "zero-1"
                                : "success-o"
                            }
                          >
                            {item.Betstatus}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="6" className="text-center">
                          No Data
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <RightSidebar />
        </div>
      </div>
    </>
  );
};

export default Aviator;

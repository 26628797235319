import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const baseURL = "https://betexch-api.nakshtech.info/";

const API = axios.create({
  baseURL,
});

const saveTeams = createAsyncThunk(
  "teams/saveTeams",
  async ({ id, mname, team, token ,sid}, { rejectWithValue }) => {
    try {
      const res = await API.post(
        `/saveTeams`,
        {
          matchid: id,
          mname: mname,
          Team: team,
          sid: sid,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const teamsSlice = createSlice({
  name: "teams",
  initialState: {
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(saveTeams.pending, (state) => {
        state.error = null;
      })
      .addCase(saveTeams.fulfilled, (state) => {
        state.error = false;
      })
      .addCase(saveTeams.rejected, (state, action) => {
        state.error = action.payload;
      });
  },
});

export const selectTeamsLoading = (state) => state.teams.loading;
export const selectTeamsError = (state) => state.teams.error;

export { saveTeams };

export default teamsSlice.reducer;

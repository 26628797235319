import React, { useEffect, useState } from "react";
import "./Casino.css";
import game from "../game_rules.json";
import { useParams } from "react-router-dom";
import Table_Buttons from "../Table_Buttons/Table_Button";
import Table from "../Table/Table";
import { useDispatch, useSelector } from "react-redux";
import { fetchCasinobetHistory } from "../../Redux/Slices/casinobetHistory";
const CasinoResults = () => {
  const dispatch = useDispatch();

  const userId = sessionStorage.getItem("userData");
  const token = sessionStorage.getItem("token");
  const { casinobetHistory, isLoading } = useSelector(
    (state) => state.casinobetHistory
  );
  const [id, setId] = useState("");
  const [referralApi, setreferralApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(10);
  const [todate, settodate] = useState("");
  const [fromdate, setfromdate] = useState("");

  // useEffect(() => {
  //   dispatch(fetchCasinobetHistory({ uid: userId, token, id }));
  // }, [dispatch, token]);

  // useEffect(() => {
  //   if (casinobetHistory) {
  //     console.log("Fetching account statement", casinobetHistory);
  //   }
  // }, [casinobetHistory]);

  useEffect(() => {
    if (!isLoading && casinobetHistory.length > 0 && id) {
      // console.log(depositHistory.length)
      let arr = casinobetHistory.map((item, index) => ({
        sr: index + 1,
        period: item.period,
        multipler: item.multipler,
        amount: item.amount,
        dd: item.dd,
        remark: (
          <span className={item.remark === "Loss" ? "zero" : "success-1"}>
            {item.remark}
          </span>
        ),
        card: item.card,
      }));

      // console.log("arr",arr)
      setreferralApi(arr);
    } else {
      setreferralApi([]);
    }
  }, [isLoading, casinobetHistory]);

  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = referralApi.slice(indexOfFirstPage, indexOfLastPost);
  var [matching_income, set_matching_income] = useState({
    cols: [
      { Header: "Sr.No", accessor: "sr" },
      { Header: "Period Id", accessor: "period" },
      { Header: "Multiplier", accessor: "multipler" },
      { Header: "Amount	", accessor: "amount" },
      { Header: "Date", accessor: "dd" },
      { Header: "Result", accessor: "remark" },
      { Header: "Card", accessor: "card" },
    ],
  });

  const handleSearch = () => {
    dispatch(
      fetchCasinobetHistory({ uid: userId, token, id, todate, fromdate })
    );
  };
  return (
    <div className="col-lg-10 com-h">
      <div className="row">
        <div className="col-12 p-3">
          <div className="bet-h ">
            <h4 className="p-2 ms-2"> Casino Results</h4>

            <div className="dates-div p-2 d-flex ">
              <div className="row">
                {/* <div className="col-md-5 mt-1">
                  <div className="d-flex">
                    <div className="from d-flex align-items-center justify-content-center">
                      Date:
                    </div>
                    <div className="">
                      <input
                        name="start_date"
                        id="from"
                        type="date"
                        autocomplete="off"
                        placeholder="Select date from"
                        className="date-input p-1 "
                        value="2024-02-10"
                      />
                    </div>
                  </div>
                </div> */}
                <div className="col-md-12 d-flex flex-wrap">
                  <div className="col-md-4  mt-1">
                    <div className="d-flex ">
                      <div className="from d-flex align-items-center justify-content-center p-2">
                        Game
                      </div>
                      <select
                        id="dd"
                        className="from-control"
                        value={id}
                        onChange={(e) => setId(e.target.value)}
                      >
                        <option value="">Select </option>
                        {game.map((item) => (
                          <option key={item.Gameid} value={item.Gameid}>
                            {item.rules}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3 mt-1">
                    <div className="d-flex">
                      <div className="from d-flex align-items-center justify-content-center p-2">
                        From:
                      </div>
                      <div className="">
                        <input
                          className="from-control"
                          type="date"
                          value={fromdate}
                          onChange={(e) => setfromdate(e.target.value)}
                        />
                      </div>   
                    </div>
                  </div>
                  <div className="col-md-3  mt-1">
                    <div className="d-flex ">
                      <div className="from d-flex align-items-center justify-content-center p-2">
                        To:
                      </div>
                      <div className="">
                        <input
                          className="from-control"
                          type="date"
                          value={todate}
                          onChange={(e) => settodate(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2 mt-1">
                    <button
                      className="btn btn-success "
                      onClick={() => handleSearch()}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-search"
                        viewBox="0 0 16 16"
                      >
                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                      </svg>{" "}
                      Search
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <Table data={[...currentPost]} columns={matching_income.cols} />
              <Table_Buttons
                setcurrentPage={setcurrentPage}
                currentPage={currentPage}
                totalData={referralApi.length}
                listPerpage={listPerpage}
                handleRowsPerPageChange={setlistPerpage}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CasinoResults;

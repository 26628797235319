import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const API = axios.create({
  baseURL: "https://betexch-api.nakshtech.info/",
});

const initialState = {
  thirtyTwoCardStack: [],
  error: null,
};
     
export const fetchThirtyTwoCardStack = createAsyncThunk(
  "thirtyTwoCardGame/fetchStack",

  async ({ uid, gameId, token }, { rejectWithValue }) => {
    // console.log("slice", gameId, uid);
    try {
      const response = await API.get(`CasinoCalc?uid=${uid}&gameid=${gameId}`, {
        headers: {
          Authorization: token,
        },
      });

      // console.log("resp", response);
      return response.data.data[0]; // Return only the data
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const thirtyTwoCardGameSlice = createSlice({
  name: "thirtyTwoCardGame",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchThirtyTwoCardStack.fulfilled, (state, action) => {
        state.thirtyTwoCardStack = action.payload;
        state.error = null;
      })
      .addCase(fetchThirtyTwoCardStack.rejected, (state, action) => {
        state.error = action.payload;
      });
  },
});

export default thirtyTwoCardGameSlice.reducer;

// matchNameSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getMatchName } from "../api/matchNameAPI";

const initialState = {
  matchName: null,
  isLoading: false,
  error: null,
};

export const fetchMatchName = createAsyncThunk(
  "matchData/fetchMatchName",
  async ({ id, token }, { rejectWithValue }) => {
    try {
      const response = await getMatchName(id, token);
      return response;
    } catch (error) {
      // console.log("Name error", error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const matchNameSlice = createSlice({
  name: "matchName",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMatchName.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchMatchName.fulfilled, (state, action) => {
        state.isLoading = false;
        state.matchName = action.payload;
        state.error = null;
      })
      .addCase(fetchMatchName.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default matchNameSlice.reducer;

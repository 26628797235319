import axios from "axios";

const baseURL = "https://betexch-api.nakshtech.info/";
//const baseURL = "http://localhost:9476/";

const updatePassAPI = axios.create({
  baseURL,
});

export const updatePassword = async (data, token) => {
  try {
    const response = await updatePassAPI.post("/Update_pass", data, {
      headers: {
        Authorization: token,
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export default updatePassAPI;
